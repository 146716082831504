import stepOneImg from './how-to-find-fpl-id-step-1.webp'
import stepTwoImg from './how-to-find-fpl-id-step-2.webp'
import stepThreeImg from './how-to-find-fpl-id-step-3.webp'
import stepFourImg from './how-to-find-fpl-id-step-4.webp'
import { Link } from 'react-router-dom'
import { BlogPostLayout } from './BlogPostLayout'

export default function BlogPost() {
    return (
        <BlogPostLayout
            canonicalUrl='/blog/how-to-find-fpl-id'
            metaDescription='Easily learn how to find your FPL ID in 2024 using this simple guide!'
            metaTitle='How to find your FPL Team ID in 2024'
            postTitle='How to find your FPL ID in 2024: A simple Step-by-Step Guide!'>

            <h2>1. Navigate to the Fantasy Premier League Website at fantasy.premierleague.com</h2>
            <img src={stepOneImg} alt="Picture of the Fantasy Premier League Website." />
            <h2>2. Sign In to your FPL Account</h2>
            <img src={stepTwoImg} alt="Picture of the Log-In Section of the Fantasy Premier League Website." />
            <h2>3. Click on the 'Points' tab</h2>
            <img src={stepThreeImg} alt="The Points tab of the Fantasy Premier League website highlighted." />
            <h2>4. Look at the URL in the address bar. The number between "/entry/" and "/event/" is your FPL ID!</h2>
            <img src={stepFourImg} alt="URL bar of the browser showing where the FPL Team ID is located." />
            <h2>5. Start Planning your Team using the fplstrat.app <Link to={"/planner"}>Transfer Planner</Link>!</h2>

        </BlogPostLayout>

    )
}

