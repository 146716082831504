import { Route, Routes } from 'react-router-dom';
//import TransferPlanner from '../TransferPlanner/TransferPlanner';
import HeroPage from '../HeroPage/HeroPage';
import styles from './App.module.scss';

import LoginPage from '../LoginPage/LoginPage';
import RegisterPage from '../RegisterPage/RegisterPage';
import { Contact } from '../Contact/Contact';
import { ErrorFallback } from '../ErrorFallback/ErrorFallback';
import { lazy } from 'react';
import TransferPlanner from '../TransferPlanner/TransferPlanner';
import ManageAccountPage from '../ManageAccountPage/ManageAccountPage';
import { ProtectedRoutes } from '../ProtectedRoutes/ProtectedRoutes';
import FixtureTickerPage from '../FixtureTickerPage/FixtureTickerPage';
import AboutPage from '../AboutPage/AboutPage';
import BlogPage from '../BlogPage/BlogPage';
import BlogPost from '../BlogPage/BlogPosts/FindIdBlogPost';
import Gw16WCBlogPost from '../BlogPage/BlogPosts/Gw16WCBlogPost';
import Gw17WCBlogPost from '../BlogPage/BlogPosts/Gw17WCBlogPost';



export function App() {
    return (
        <Routes>
            <Route path="/planner" element={<TransferPlanner />} />
            <Route path='/blog/how-to-find-fpl-id' element={<BlogPost />} />
            <Route path='/blog/fpl-gw-16-wildcard-team' element={<Gw16WCBlogPost/>}></Route>
            <Route path="/blog/fpl-gw-17-wildcard-team" element={<Gw17WCBlogPost />} />
            <Route path="/blog" element={<BlogPage />} />

            <Route path="/share/*" element={<TransferPlanner />} />
            <Route path="/" element={<HeroPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/fpl-fixture-ticker" element={<FixtureTickerPage />} />
            <Route element={<ProtectedRoutes />}>
                <Route path="/manage-account" element={<ManageAccountPage />} />
            </Route>
            <Route path="*" element={<ErrorFallback error={Error("404 - page not found!")} resetErrorBoundary={() => { }} />} />
        </Routes>

    )
}