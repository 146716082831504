import styles from './StandardLayout.module.scss'
import { Header } from '../Header/Header'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet'

type LayoutProps = {
    children: React.ReactNode;
    metaTitle: string;
    metaDescription: string;
    canonicalUrl: string;
    smallPadding?: boolean;
}

export const StandardLayout: React.FC<LayoutProps> = ({ children, metaTitle, metaDescription, canonicalUrl, smallPadding }) => {
    return (
        <section className={styles.main}>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="robots" content="index,follow" />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <Header notInPlan={true} />
            <div className={`${styles.lilpadding} ${smallPadding && styles.smallpadding}`}>
                {children}
            </div>
            <Footer />
        </section>
    )
}