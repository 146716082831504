import React from 'react'
import { StandardLayout } from '../StandardLayout/StandardLayout'
import styles from './BlogPage.module.scss'
import { Link } from 'react-router-dom'

export default function BlogPage() {
    return (
        <StandardLayout canonicalUrl='/blog' metaDescription='Fantasy Premier League related bits n bops!' metaTitle='fplstrat.app | Blog' >
            <section className={styles.blogPostsSection}>
                <h1 className={styles.blogHeading}>Blog Posts</h1>

                <ul className={styles.postList}>
                <li className={styles.post}>
                        <Link to={'/blog/fpl-gw-17-wildcard-team'} className={styles.postTitle}><h2 >Our Take on the Optimal GW 17 Wildcard Team!</h2></Link>
                        <p className={styles.postExcerpt}>My keeper of choice for the GW 17 Wildcard Team is Leno (5.0). Fulham have been fantastic ...</p>
                        <Link className={styles.link} to={'/blog/fpl-gw-16-wildcard-team'}>Read More</Link>
                    </li>
                    <li className={styles.post}>
                        <Link to={'/blog/fpl-gw-16-wildcard-team'} className={styles.postTitle}><h2 >Our Take on the Optimal GW 16 Wildcard Team!</h2></Link>
                        <p className={styles.postExcerpt}>Goalkeepers: In goal we decided to go for Sanchez from Chelsea: ...</p>
                        <Link className={styles.link} to={'/blog/fpl-gw-16-wildcard-team'}>Read More</Link>
                    </li>
                    <li className={styles.post}>
                        <Link to={"/blog/how-to-find-fpl-id"} className={styles.postTitle}><h2 >How to find your FPL ID in 2024: a Simple Step-by-Step Guide</h2></Link>
                        <p className={styles.postExcerpt}>Find out how to find your FPL ID </p>
                        <Link className={styles.link} to={"/blog/how-to-find-fpl-id"}>Read More</Link>
                    </li>
                </ul>
            </section>

        </StandardLayout>
    )
}
