import styles from './BlogPostLayout.module.scss'
import { StandardLayout } from '../../StandardLayout/StandardLayout'

type BlogPostLayoutProps = {
    children: React.ReactNode;
    canonicalUrl: string;
    metaTitle: string;
    metaDescription: string;
    postTitle: string;

}


export const BlogPostLayout: React.FC<BlogPostLayoutProps> = ({ children, canonicalUrl, metaTitle, metaDescription, postTitle }) => {
    return (
        <StandardLayout
            canonicalUrl={canonicalUrl}
            metaDescription={metaDescription}
            metaTitle={metaTitle}
        >
            <section className={styles.blogPostMainSection}>
                <h1>{postTitle}</h1>
                {children}
            </section>
        </StandardLayout>
    )
}