import styles from './HeroPage.module.scss'

import { Link } from 'react-router-dom'

import { StandardLayout } from '../StandardLayout/StandardLayout'

import redArrow from './red-arrow.svg'
import greenArrow from './green-arrow.svg'

function HeroPage() {
    return (
        <StandardLayout
            canonicalUrl='https://fplstrat.app'
            metaDescription='Climb the Ranks of Fantasy Premier League using the most advanced free FPL Transfer Planner!'
            metaTitle='FPL Transfer Planner'>



            <section className={styles.mainSection}>
                <section className={styles.heroTitleSection}>
                    <h1 className={styles.seoTitle}>FPL Transfer Planner</h1>
                    <p className={styles.heroTitle}>Turn <img src={redArrow} alt="red arrow down" />  into  <img src={greenArrow} alt="green arrow up" />!<br></br></p>
                    <p className={styles.heroSubtitle}>
                        Gain an edge on your mini-league opponents using the <span className={styles.highlight}>best Planning UI</span> of any free FPL Transfer Planner,
                        a <span className={styles.highlight}>powerful Fixture Ticker</span>, a <span className={styles.highlight}>smart Stat Overview for Players</span> and


                        <span className={styles.highlight}> (optional) xG based FDR. </span><br /><br />
                        Start planning now and <span className={styles.highlight}> turn your Fantasy Premier League season around! 📈📈</span>

                    </p>



                    <div className={styles.btnContainer}>
                        <Link className={`${styles.startPlanningBtn} plausible-event-name=click+start+planning`} to="/planner" >{"->"} start planning!</Link>

                    </div>


                </section>

                


                <section className={styles.screenshots}>

                    <h2 className={styles.subTitle}>Screenshots</h2>

                    <div className={styles.screenshotsContainer}>
                        <section className={styles.heroImageSection}>

                            <img className={styles.screenshot} srcSet="fpl-transfer-planner-sm.webp 224w, fpl-transfer-planner.webp 618w"
                                sizes="(min-width: 450px) 618px, 224px"
                                alt="fpl-transfer-planner screenshot" />
                            <p><Link to={"/planner"}>planner</Link> with stats overview opened</p>
                        </section>

                        <section className={styles.heroImageSection}>
                            <img className={styles.screenshot} srcSet="ticker-screenshot-sm.webp 224w, ticker-screenshot.webp 618w"
                                sizes="(min-width: 450px) 618px, 224px"
                                alt="fpl-fixture-ticker screenshot" />
                            <p><Link to={"/fpl-fixture-ticker"}>fixture ticker</Link> with xG based FDR enabled</p>

                        </section>
                    </div>

                    <div className={styles.btnContainer}>
                        <Link className={`${styles.startPlanningBtn} plausible-event-name=click+start+planning`} to="/planner" >{"->"} start planning!</Link>

                    </div>


                </section>

                <section className={styles.promo}>
                    <h2 className={styles.subTitle}>How to use the fplstrat.app Planner</h2>
                    <iframe className={styles.howToVid} src="https://www.youtube.com/embed/m_csh3aTfDA?si=OJZppG4_s5Ajv_NO" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                </section>

                <section className={styles.promo}>
                    <h2 className={styles.subTitle}>
                        FAQ
                    </h2>
                    <h3 className={styles.question}>Why should I use this FPL transfer planner over a similar tool like fpl.team?</h3>
                    <p>While we do share a lot of basic functionality (such as viewing your Fantasy Premier League
                        Team in future Gameweeks), the fplstrat.app <Link to={"/planner"}>Planner</Link> has a smoother user experience, an <Link to={"/fpl-fixture-ticker"}>advanced fixture ticker</Link>,
                        and a great statistics overview for players. <br /> <br />
                        The xG based FDR is also a feature not found on any other planning tool, giving you a much better idea on opponent strength. <br /> <br />
                        It is also <span className={styles.highlight}>the only FPL planning tool truly optimised for your phone!</span>
                    </p>
                    <h3 className={styles.question}>What is the xG-based FDR?</h3>
                    <p>The fplstrat.app xG-based FDR is an advanced Fixture Difficulty Rating, giving you a better insight into the strength (both offensively and defensively) of your opponents. <br /> <br />
                        The offensive strength of any team is calculated using the average xG per 90 the team managed to achieve over the last 38 games, the defensive strength using the
                        xGc (expected Goals conceded) per 90 over the same period. Then they are ranked according to their relative performance,
                        giving the FDR you can see in our <Link to={"/planner"}>Planner</Link>. <br /><br />
                        This is super useful as the official FDR can be very misleading at times! Some teams are strong in defence but weak in attack,
                        and giving them one Difficulty (like you can find on the official app, and other planning tools) does not give you a good idea of your teams opponents' strength. <br /><br />
                        To make things super easy for you, our <Link to={"/planner"}>planning tool</Link> automatically shows the opponents' offensive strength on your defenders and defensive strength on your attackers!
                    </p>

                    <h3 className={styles.question}>Is it easy to do FPL player comparison using this planner?</h3>
                    <p>Yes, the player statistics overview in our <Link to={"/planner"}>Planner</Link> makes it super simple to compare different players,
                        and work out which one to transfer in for for maximum returns! <br /><br />
                    </p>

                    <div className={styles.btnContainer}>
                        <Link className={`${styles.startPlanningBtn} plausible-event-name=click+start+planning`} to="/planner" >{"->"} start planning!</Link>

                    </div>
                </section>

            </section>


        </StandardLayout>
    )
}

export default HeroPage