import { Link } from 'react-router-dom'
import { BlogPostLayout } from './BlogPostLayout'
import gw16WCImg from './gw16img/fpl-gw-16-wildcard.webp'
export default function Gw16WCBlogPost() {
    return (
        <BlogPostLayout
            metaTitle='Optimal FPL GW 16 Wildcard Team'
            metaDescription='Check out our take on the optimal FPL GW 16 Wildcard Team!'
            postTitle='FPL GW 16 Wildcard Team'
            canonicalUrl='/blog/fpl-gw-16-wildcard-team'>
            <img src={gw16WCImg} alt="Picture of the optimal GW 16 Wildcard Team showing all positions." />
            <h2>Goalkeepers</h2>
            <p><span>Sânchez (4.8)</span> is our keeper of choice for Chelseas excellent fixture run going forward! <br /><br />
                The second GK spot goes to <span>Fabianski (4.1)</span> as a playing 4.1 GK is too good to pass up! If you need the money elsewhere, going for a non-playing 4.0 is perfectly fine as well.</p>
            <h2>Defenders</h2>
            <p>The defence basically picks itself - <span>Timber (5.6)</span> and <span>Gabriel (6.2)</span> appear to be the top picks of the top defense - while they have
                struggled on occasion, provided that Gabriel and Saliba stay fit (and the team manages to avoid red cards) I have no doubt that they'll provide plenty of clean sheets! <br /><br />
                <span>Alexander-Arnold (6.9)</span> is an absolute steal at this price-point, while there will be the odd benching over the festive period, his attacking threat and Liverpools excellent defending will make for a plethora of returns.  <br /><br />
                <span>Hall (4.4)</span> is excellent value at 4.4, he has been starting consistently and their fixtures going forward are among the best from a defensive point of view. <br /><br />
                <span>Greaves (4.0)</span> is 4.0, nailed, and Ipswich hasn't even looked that bad! Still we're obviously hoping to never have to rely on him for points.
            </p>
            <h2>Midfielders</h2>
            <p>On to the midfielders - <span>Saka (10.5)</span>, <span>Palmer (11.0)</span> and <span>Salah (13.3)</span> are essential players going forward. All of them have been returning consistently and have good to great long-term fixtures. <br /><br />
                <span>Gordon (7.2)</span> is a bit of a sleeper pick - I'm not seeing him in many teams, but Newcastles fixtures look fantastic from GW 16 onwards, and I'm confident that they are gonna improve as a team. Gordon has been nailed to start and has strong underlyings, I think he'll give us plenty to smile about. <br /><br />
                <span>Rogers (5.3)</span> completes our midfield. He's been so good for the lucky (skilled?) managers owning him, and he'll be a great first bench option for the forseeable.</p>
            <h2>Forwards</h2>
            <p><span>Joao Pedro (5.8)</span> has been super involved in the good Brighton attack, and appears to be a nailed starter. They have good fixtures going forward, so what's not to like? <br /><br />
                <span>Wood (6.5)</span> is a bit of a pet peeve player of mine - it was such a close call between him and Muniz in the beginning. I went for Muniz. Wood kept scoring points. So you should definitely have him in your GW 16 WC team! Good fixtures, nailed, on penalties, just do it. <br /><br />
                And the last player in our lineup is <span>Strand Larsen (5.6)</span>. While his underlyings haven't been the best, they are trending upwards, and he has been in a scoring mood lately, so he's my favourite budget pick with excellent short term fixtures! Jimenez might be an alternative - he's been in strong form lately, but I'd put him on the bench in Gameweek 16. <br /><br />
                <Link to={"/planner"}>Click here</Link> to start planning your Wildcard using our Transfer Planner!</p>

        </BlogPostLayout>
    )
}