import { Link } from 'react-router-dom'
import { BlogPostLayout } from './BlogPostLayout'
import img from './gw17img/fpl-gw-17-wildcard.webp'

export default function Gw17WCBlogPost() {
    return (
        <BlogPostLayout
            metaTitle='Optimal FPL GW17 Wildcard Team'
            metaDescription='Check out our take on the optimal FPL GW17 Wildcard Team for 24/25!'
            postTitle='FPL GW 17 Wildcard Team'
            canonicalUrl='/blog/fpl-gw-17-wildcard-team'>
            <img src={img} alt="Picture of the optimal GW 17 Wildcard Team showing all positions." />
            <h2>Goalkeepers</h2>
            <p>My keeper of choice for the GW 17 Wildcard Team is <span>Leno (5.0)</span>. Fulham have been fantastic defensively (5th in the League for xG conceded!) and have good fixtures going forward.<br /> <br />             
            Our number 2 remains <span>Fabianski (4.1)</span>. He gets minutes, he's 4.1, he's in. If money is tight I wouldn't mind going for a non-playing 4.0 option either.
            </p> 

            <h2>Defenders</h2>
            <p>I know what you're thinking here - that's way too much to spend on defenders in a season with so few clean sheets! Liverpool and Arsenal however have consistently conceded less than <span>1 xG</span>, which is absolute elite level defending.
            <br /><br />
            Add to this the cheat code that is Arsenal corners, and we have an insanely strong back 4! <br /><br />
            That's my reasoning then behind picking <span>Timber (5.6)</span>, <span>Gabriel (6.2)</span>, <span>Trent Alexander-Arnold (7.0)</span> and <span>Joe Gomez (4.9)</span> who's incredible value for a starting Liverpool defender! <br /><br />
            They have strong fixtures going forward too - feel free to check fixture runs out for yourself using our <Link to={"/fpl-fixture-ticker"}>Fixture Ticker</Link>! It shows you both attacking AND defending difficulty if you enable the xG based FDR! <br /><br />
            If your budget is a little on the low side I'd recommend <span>Robinson (4.7)</span> as a budget pick. Decent attacking threat, part of a good defense.</p> 
            <h2>Midfielders</h2>
            <p>The Big Three here are FPL must-haves in my opinion - <span>Salah (13.3)</span>, <span>Palmer (11.1)</span> and <span>Saka (10.5)</span> are all crushing it. I would not want to miss out on their points. <br /><br />
            <span>Enzo (5.0)</span> is a solid enabler who's been ticking along nicely as of late, and there's no-one much better you could get at this price point. <br /><br />
            <span>Dibling (4.5)</span> probably won't be the one winning you the season, but Southampton do have the best offensive fixture run and he is nailed to start, with attacking threat!</p>
            <h2>Forwards</h2>
            <p>
                Now this is a front three I'm certain you'll have a lot of fun with! <br /><br />
                <span>Raúl (5.5)</span> has been doing very well lately and is playing for a very strong Fulham team. <br /><br />
                <span>Isak (8.6)</span> has strong stats, been getting more returns, and there's a good chance he's on penalties! <br /><br />
                Finishing our lineup then is <span>Wood (6.5)</span>. He's on penalties and is showing no signs of slowing down! At 33 years old there probably will be the odd benching during the festive period, but he's a fantastic option nonetheless.
            </p>

            <h2>Conclusion</h2>
            <p>This turned out to be the most defense-heavy squad I have put together in ages! <br /><br /> 
            Feel free to take inspiration and play around with your own draft using our advanced <Link to={"/planner"}>Planning Tool</Link>, and best of luck for gameweek 17! <br /><br />
            - Fred</p>

        </BlogPostLayout>
    )
}